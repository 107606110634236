class wdbScrollAnimate {
  
  constructor(e, s) {

    this.targetElement = e;
    this.elemClass = s;
    this.activationPoint = window.innerHeight / 1.325;
    this.init();

  }

  init() {

    const _self = this;
    _self.trackScroll();
    _self.resizeActivation();

  }

  resizeActivation() {
    
    const _self = this;
    
    window.addEventListener('resize', function() {      
      _self.activationPoint = window.innerHeight / 1.325;
    });

  }
  
  setScrollProp() {
    document.body.style.setProperty('--scroll', window.scrollY + 'deg');
  }

  toggleClass() {
    
    const _self = this;
    const elementPosition = _self.targetElement.offsetTop - _self.activationPoint;

    if (window.scrollY >= elementPosition) {
      _self.targetElement.classList.add(_self.elemClass);
    } else {
      _self.targetElement.classList.remove(_self.elemClass);
    }

  }

  trackScroll() {    
    const _self = this;
    window.addEventListener('scroll', () => {
      _self.toggleClass()
      _self.setScrollProp()
    });    
  }
}

let wdbScrollAnimateObj;

function wdbScrollAnimateInstance(e) {
  
  let targetName = '.scroll-enable-' + e;
  let styleName = 'scroll-' + e + '-anim';
  let targetElement = document.querySelectorAll(targetName);
  
  targetElement.forEach(element => {
    wdbScrollAnimateObj = new wdbScrollAnimate(element,styleName)
  }); 

}

wdbScrollAnimateInstance('fade-up');
wdbScrollAnimateInstance('fade-left');
wdbScrollAnimateInstance('fade-right');

// add following CLASS to target element to enable
// "scroll-enable-fade-up"
// when activated, the second class will added to fire CSS animation
// "scroll-fade-up-anim"