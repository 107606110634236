function updateProgressBar(){    
    const {scrollTop} = document.documentElement;
    
    // const contentHeight = document.querySelector('.entry-content').offsetHeight;
    // const titleHeight = document.querySelector('.wdb-post-header').offsetHeight;
    // const addedHeight = contentHeight + titleHeight + imageHeight + 250;
    //  const scrollPercent = scrollTop / (addedHeight - window.innerHeight) * 100 + '%';
    
    let mainEl;
    if (document.querySelector('#relposts')) {
      // const imageHeight = document.querySelector('.post-featured-image').offsetHeight;
      mainEl = document.querySelector('.entry-content').offsetHeight + document.querySelector('.post-featured-image').offsetHeight + 250;
    } else {
      mainEl = document.querySelector('.entry-content').offsetHeight + 250;
    }
    const scrollPercent = scrollTop / (mainEl - window.innerHeight) * 100 + '%';
    document.querySelector('#wdbprogBar').style.setProperty('--wdb-js-progress', scrollPercent);
  }

  document.addEventListener("DOMContentLoaded", function () {
    if (document.body.classList.contains('single-post') === true) {
      document.addEventListener('scroll', updateProgressBar);
    }
  });
