document.addEventListener("DOMContentLoaded", function () {
  const backToTopBtn = document.getElementById("toTop");
  window.addEventListener("scroll", function () {
    const fromTop = 900;
    if (document.body.scrollTop > fromTop || document.documentElement.scrollTop > fromTop) {
      backToTopBtn.setAttribute('aria-hidden', 'false');
      backToTopBtn.removeAttribute('tabindex');
    } else {
      backToTopBtn.setAttribute('aria-hidden', 'true');
      backToTopBtn.setAttribute('tabindex', '-1');
    }
  });
  backToTopBtn.addEventListener("click", function () {
    scrollToTop();
  });
  function scrollToTop() {
    var currentPosition = document.documentElement.scrollTop || document.body.scrollTop;
    if (currentPosition > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, currentPosition - currentPosition / 8);
    }
  }
});