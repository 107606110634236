/* jshint esversion: 6 */
/*
TMS Cookie Preferences
*/
// creates cookie preferences menu item in the legal menu in the footer
jQuery(document).ready(function() {
	document.getElementById('teconsent').style.display = 'flex';
});


//This is your domain, as in, how you who are calling the API wish to be identified.
var MY_DOMAIN = "gdprdemoclient_api_banner.com";
var REQUIRE_USER_EXPRESSED_PERMISSION = true;
var _STATE = {};

/*
 * YTNC check - notice HTML
 */
let HAS_FUNCTIONAL = 0;
const myCode = `<div class="video-notice"><div class="banner"><figure><svg xmlns="http://www.w3.org/2000/svg" height="80" width="120" viewBox="-35.20005 -41.33325 305.0671 247.9995"><path d="M229.763 25.817c-2.699-10.162-10.65-18.165-20.748-20.881C190.716 0 117.333 0 117.333 0S43.951 0 25.651 4.936C15.553 7.652 7.6 15.655 4.903 25.817 0 44.236 0 82.667 0 82.667s0 38.429 4.903 56.85C7.6 149.68 15.553 157.681 25.65 160.4c18.3 4.934 91.682 4.934 91.682 4.934s73.383 0 91.682-4.934c10.098-2.718 18.049-10.72 20.748-20.882 4.904-18.421 4.904-56.85 4.904-56.85s0-38.431-4.904-56.85" fill="red"/><path d="M93.333 117.559l61.333-34.89-61.333-34.894z" fill="#fff"/></svg></figure><p>We have embedded content from YouTube here. As <strong>YouTube may collect personal data and track your viewing behavior</strong>, we will only load the video after you consent to the use of <strong>Functional Cookies</strong> and similar technologies as described in their <a target="_blank" href="https://policies.google.com/privacy?hl=en-US">privacy policy</a>.</p><a href="#teconsent">Change Cookie Preferences</a></div></div>`;

/**
* Different pages add the Consent Manager in different locations, so all callers of the API must wait till
* the API is loaded. The API is loaded in two stages:
*      1) The first stage is where the "PrivacyManagerAPI" object exists on the page and where default and
*         page/domain specific settings can be obtained. If your requirements demand user consent, you must wait
*         for the second stage load, but it is always recommended to wait for the second stage no matter what.
*         The "loading" parameter will be added to all API responses when the API is in this state.
*      2) The second stage loads the user preferences and the domain specific information. If you made a
*         postMessage API call during the first stage, then the API will automatically send you another, updated,
*         response if the result has changed.
*/
function runOnce(){
	//CHECK: for API exists on the page
	if(!_STATE.hasRunOnce && window.PrivacyManagerAPI){
		//Register with the API for automatic updates of user preferences (for the settings you care about)
		//--OR-- if the API is loading, then this will send an update when the API is done and has loaded the user preferences.
		window.addEventListener("message", function(e){
			try{
				var json = JSON.parse(e.data);
				json.PrivacyManagerAPI && handleAPIResponse(json.PrivacyManagerAPI);
			}catch(e){
				e.name != 'SyntaxError' && console.log(e);
			}
		}, false);
		var apiObject = {PrivacyManagerAPI: { self: MY_DOMAIN, action: "getConsent" , timestamp: new Date().getTime(), type: "functional" }};
		window.top.postMessage(JSON.stringify(apiObject),"*");
		apiObject = {PrivacyManagerAPI: { self: MY_DOMAIN, action: "getConsent" , timestamp: new Date().getTime(), type: "advertising" }};
		window.top.postMessage(JSON.stringify(apiObject),"*");
		_STATE.hasRunOnce = true;
		_STATE.i && clearInterval(_STATE.i);
	}
}
/**
    * This function returns value of notice_behavior cookie to determine location and behavior manager based on domain.
* When no notice_behavior cookie exists, this returns a blank string.
*/
function getBehavior() {
	var result = "";
	var rx = new RegExp("\\s*notice_behavior\\s*=\\s*([^;]*)").exec(document.cookie);
	if(rx&&rx.length>1){
		result = rx[1];
	}
	return result;
}
/**
* This function is called whenever a user preference is initially set, is retrieved for the first time on this page, or is updated.
* This is the gateway function which should be customized by each client (you) to determine when and how to handle the API response.
*
* The second half of the function determines settings from the CM API, and decides which elements on the page should be "activated" based upon those settings.
* Elements can only be activated once. Elements can not be deactivated, once activated.
*/
function handleAPIResponse(response){
	//CHECK: make sure this response is to YOU. You will actually get the messages to all API callers on this page, not just to you.
	if(!response.source || response.self != MY_DOMAIN ) return;
	console.log("user decision",response);
	//Required trackers/cookies are always allowed, no need to ask permission.
	if( !_STATE.hasLoadedRequired ){
		activateElement(document.querySelectorAll(".trustecm[trackertype=required]"));
		_STATE.hasLoadedRequired = true;
	}
	// Check if behavior manager is EU
	var isEU = /.*(,|)eu/i.test(getBehavior());
	//Case where we don't want to do anything till the user has made a preference.
	if(isEU && REQUIRE_USER_EXPRESSED_PERMISSION && response.source != "asserted" ) return;
	//Step 1) Get Consent Manager settings (user prefs)
	//        These API calls are DIFFERENT than the original API call ("response" parameter) so they must be called separately.
	//Step 2) Apply the settings after checking if approved
	var setting = null;
	if( !_STATE.hasLoadedAdvertising ){
		setting = PrivacyManagerAPI.callApi("getConsent", MY_DOMAIN , null ,null, "advertising");
		if( setting.consent=="approved" ){
			activateElement(document.querySelectorAll(".trustecm[trackertype=advertising]"));
			_STATE.hasLoadedAdvertising = true;
		}console.log(setting);
	}
	if( !_STATE.hasLoadedFunctional ){
		setting = PrivacyManagerAPI.callApi("getConsent", MY_DOMAIN , null ,null, "functional");
		if( setting.consent=="approved" ){
			activateElement(document.querySelectorAll(".trustecm[trackertype=functional]"));
			_STATE.hasLoadedFunctional = true;
			// YTNC check - has functional ON
			// Sets variable and adds message to the log
			HAS_FUNCTIONAL = 1;
			console.log('functional cookies->'+setting.consent);
		} else {
			// YTNC check - has functional OFF
			// Sets variable and adds message to the log
			HAS_FUNCTIONAL = 0;
			console.log('functional cookies->'+setting.consent);
			// Replaces all iframes
			ytncCheck(HAS_FUNCTIONAL);
		}
		console.log(setting);
	}
	// No additional checking, this always fires, but only after a user has consented
	if( !_STATE.hasLoadedAnyConsent ){
		activateElement(document.querySelectorAll(".trustecm[trackertype=any]"));
		_STATE.hasLoadedAnyConsent = true;
	}
	//check of vendor domain and fires if that domain is approved, which is based on how that domain was categorized on the backend
	var vendors = document.querySelectorAll(".trustecm[trackertype=vendor]");
	for (var i=0; i < vendors.length; i++) {
		var currentVendor = vendors[i];
		var vDomain = currentVendor.getAttribute("vsrc");
		if (vDomain && !_STATE['hasLoaded'+vDomain]) {
			setting = PrivacyManagerAPI.callApi("getConsent", MY_DOMAIN, vDomain);
			if( setting.consent=="approved" ){
				activateElement(document.querySelectorAll(".trustecm[trackertype=vendor][vsrc='"+ vDomain +"']"));
				_STATE['hasLoaded'+vDomain] = true;
			}console.log(setting);
		}
	}
}
/**
* Activates (runs, loads, or displays) an element based upon element node name.
* @param {Array.<HTMLElement>} list
*/
function activateElement(list){
	if(!(list instanceof Array || list instanceof NodeList)) throw "Illegal argument - must be an array";
	console.log("activating", list);
	for(var item,i=list.length;i-- >0;){
		item = list[i];
		item.class = "trustecm_done";
		switch(item.nodeName.toLowerCase()){
			case "script":
				var z = item.getAttribute("thesrc");
				if(z){
					var y = document.createElement("script");
					y.src = z;
					y.async = item.async;
					item.parentNode.insertBefore(y,item);
				}else eval(item.text || item.textContent || item.innerText);
		}
	}
}
/*
 * YTNC check
 * YouTube no-cookie embed replace
 * Replaces all iframes for the cookie notice if the user
 * doesn't have the Functional Cookies ON
 */
function ytncCheck(nr){
	if(nr == 0){
		var x = document.getElementsByTagName("iframe");
		var i = 0;
		while(i < x.length){
			var currentNode = x[i];
			//console.log(currentNode.id);
			var newNode = document.createElement('div');
			newNode.className = 'notice';
			newNode.innerHTML = myCode;
			currentNode.parentNode.replaceChild(newNode, currentNode);
		}
	}
}
_STATE.i = setInterval(runOnce,10);
