/* jshint esversion: 6 */
// roundabout for the Newsletter form to avoid user sending several requests //
jQuery(document).ready(function(){
  jQuery("#newsletter").on("submit", function(event){
    let warningMsg = "You already subscribed, please check your email.";
    let errorMsg = "An error occurred, please try again later.";
    let successMsg = "Thank you! Please check your email.";
    let captchaMsg = "Captcha validation is required."
    // check last time form was submitted
    let today = new Date();
    const dtLast = localStorage.getItem('dateLast');
    var x = 0;
    if ( dtLast != null ) {
      x = dateDiff( today , dtLast );
    }
    event.preventDefault();
    // check recaptcha answer
    var response = checkRecaptcha();
    if ( response == true ) {
      // if never submitted before or submitted more than 24h ago, try again
      if ( dtLast == null || x > 1 ) {
          var formValues= jQuery(this).serialize();
          let actionUrl = "https://www.westerndigital.com/bin/wd/acs/formsubmit";
          let successUrl = "https://blog.westerndigital.com/welcome/";
          jQuery.ajax({
            method: "POST",
            url: actionUrl,
            data: formValues,
            dataType: "json",
            success: function(result, message){
              console.log("[Subscription form] " + message );
              jQuery("#mailStatus").html(successMsg);
              document.getElementById("submitNews").disabled = true;
              localStorage.setItem('dateLast', new Date() );
            },
            error: function(xhr){
              console.log("[Subscription form] " + xhr.status + " - " + xhr.statusText);
              jQuery("#mailStatus").html(errorMsg);
              document.getElementById("submitNews").disabled = false;
            }
          });
      } else { // if already submitted in the last 24h
        document.getElementById("submitNews").disabled = true;
        jQuery("#mailStatus").html(warningMsg);
      }
    } else {
      alert( captchaMsg );
      document.getElementById("recaptcha").focus();
      return false;
    }

  });
});
// Calculates the difference between 2 dates (IN DAYS)
function dateDiff ( date1 , date2 ) {
  let date3 = new Date(date1);
  let date4 = new Date(date2);
  var diff = date3.getTime() - date4.getTime();
  let TotalDays = Math.ceil(diff / (1000 * 3600 * 24));
  return  TotalDays;
}
/*
 * Check recaptcha results
 * Using Google ReCaptcha v2
 * See newsletter form on newsletter page
 */
function checkRecaptcha() {
  var response = grecaptcha.getResponse();
  if(response.length == 0) {
    return false;
  }
  else {
    return true;
  }
}
